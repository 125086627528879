import React, { useState } from "react";
import axiosInstance from "../../api/api";
import { useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn"; // Library for password strength checking
import Banner from "../ui/Banner";

const Register = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");
	const [passwordStrength, setPasswordStrength] = useState(null);
	const [passwordMatchError, setPasswordMatchError] = useState("");
	const navigate = useNavigate();

	const handlePasswordChange = (password) => {
		setPassword(password);
		const result = zxcvbn(password);
		setPasswordStrength(result.score);
	};

	const handleConfirmPasswordChange = (confirmPassword) => {
		setConfirmPassword(confirmPassword);
		if (password !== confirmPassword) {
			setPasswordMatchError("Passwords do not match.");
		} else {
			setPasswordMatchError("");
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (passwordStrength < 1) {
			setError("Password is too weak. Please choose a stronger password.");
			return;
		}
		if (password !== confirmPassword) {
			setError("Passwords do not match.");
			return;
		}

		try {
			const response = await axiosInstance.post("/users/register/", {
				first_name: firstName,
				last_name: lastName,
				username,
				email,
				password,
			});
			navigate("/login");
		} catch (error) {
			if (error.response && error.response.data) {
				const errors = error.response.data;
				if (errors.email) {
					setError(`${errors.email.join(" ")}`);
				} else if (errors.username) {
					setError(`${errors.username.join(" ")}`);
				} else {
					setError("Registration failed. Please check your input.");
				}
			} else {
				setError("Registration failed. Please try again.");
			}
		}
	};

	return (
		<div>
			<Banner />

			<div className="flex flex-col items-center justify-center min-h-[795px]">
				<div className="bg-white bg-opacity-80 p-8 rounded-xl shadow-md w-full max-w-md">
					<h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
						Create Account
					</h2>
					<form
						onSubmit={handleSubmit}
						className="space-y-4"
					>
						{/* First Name and Last Name Side by Side */}
						<div className="flex space-x-4">
							<input
								type="text"
								placeholder="First Name"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								required
								className="w-1/2 px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
							/>
							<input
								type="text"
								placeholder="Last Name"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								required
								className="w-1/2 px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
							/>
						</div>
						<input
							type="text"
							placeholder="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
							className="w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
						/>
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
						/>
						{/* Password and Confirm Password Side by Side */}
						<div className="flex space-x-4">
							<input
								type="password"
								placeholder="Password"
								value={password}
								onChange={(e) => handlePasswordChange(e.target.value)}
								required
								className="w-1/2 px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
							/>
							<input
								type="password"
								placeholder="Confirm Password"
								value={confirmPassword}
								onChange={(e) => handleConfirmPasswordChange(e.target.value)}
								required
								className="w-1/2 px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
							/>
						</div>
						{passwordStrength !== null && (
							<p
								className={`text-sm ${
									passwordStrength >= 3 ? "text-green-600" : "text-red-600"
								}`}
							>
								Password Strength:{" "}
								{
									["Very Weak", "Weak", "Medium", "Strong", "Very Strong"][
										passwordStrength
									]
								}
							</p>
						)}
						{passwordMatchError && (
							<p className="text-red-500 text-sm">{passwordMatchError}</p>
						)}
						{error && <p className="text-red-500 text-sm">{error}</p>}
						<button
							type="submit"
							className="w-full py-3 bg-black text-white rounded-md hover:scale-105 transition duration-300"
							disabled={passwordStrength < 2 || password !== confirmPassword}
						>
							Create Account
						</button>
					</form>
					<p className="text-center text-sm text-gray-600 mt-4">
						Already have an account?{" "}
						<a
							href="/login"
							className="text-blue-500 hover:underline"
						>
							Login
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Register;
