import React, { useState, useEffect } from "react";
import axiosInstance, { clearTokensAndLogout, checkAuth } from "../../api/api";
import { useNavigate } from "react-router-dom"; // For navigation to checkout page
import Banner from "../ui/Banner";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination"; // Assuming you have the pagination components

const Dashboard = () => {
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [profileImage, setProfileImage] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [orderError, setOrderError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 5; // Number of orders per page
  const pageWindowSize = 5;

  const navigate = useNavigate(); // For navigation to checkout

  // Calculate the range of page links to display in the pagination
  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(pageWindowSize / 2));
    const end = Math.min(totalPages, start + pageWindowSize - 1);

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };
  // Function to fetch the current user profile data
  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get("/users/profile/");
      setUserInfo(response.data);
    } catch (err) {
      if (err.response?.status === 401) {
        const authStatus = await checkAuth();
        if (authStatus === "authenticated") {
          fetchUserProfile();
        } else {
          clearTokensAndLogout();
        }
      } else {
        console.error("Failed to fetch user profile:", err);
      }
    }
  };

  // Function to fetch order history
  const fetchOrderHistory = async () => {
    try {
      setLoadingOrders(true);
      const response = await axiosInstance.get("/orders/");
      setOrderHistory(response.data);
    } catch (err) {
      if (err.response?.status === 401) {
        const authStatus = await checkAuth();
        if (authStatus === "authenticated") {
          fetchOrderHistory();
        } else {
          setOrderError("Failed to refresh token, please log in again.");
          clearTokensAndLogout();
        }
      } else {
        setOrderError("Failed to fetch order history");
        console.error(err);
      }
    } finally {
      setLoadingOrders(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
    fetchOrderHistory();
  }, []);

  // Handle input changes for first_name, last_name, email, phone
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  // Handle profile image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setProfileImage(URL.createObjectURL(file));
  };

  // Handle user information update form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put("/users/profile/", userInfo);
      setUpdateSuccess("Profile updated successfully!");
    } catch (err) {
      if (err.response?.status === 401) {
        const authStatus = await checkAuth();
        if (authStatus === "authenticated") {
          handleFormSubmit(e);
        } else {
          setUpdateSuccess("Failed to update profile. Please log in again.");
          clearTokensAndLogout();
        }
      } else {
        console.error("Failed to update profile:", err);
        setUpdateSuccess("Failed to update profile. Please try again.");
      }
    }
  };

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orderHistory
    .slice()
    .reverse()
    .slice(indexOfFirstOrder, indexOfLastOrder);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(orderHistory.length / ordersPerPage);

  // Function to handle "Order Again" and take user to checkout with pre-filled cart
  const handleOrderAgain = async (orderId) => {
    try {
      const response = await axiosInstance.post(`/order-again/${orderId}/`);
      if (response.status === 201) {
        // Redirect user to checkout or cart page after successfully adding items to the cart
        navigate("/checkout");
      }
    } catch (error) {
      console.error("Failed to reorder items", error);
    }
  };

  return (
    <div>
      <Banner />
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-8">
        <h1 className="text-3xl font-semibold mb-6 text-center">
          User Dashboard
        </h1>

        {/* User Info Form */}
        <form onSubmit={handleFormSubmit} className="mb-8 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={userInfo.first_name || ""}
                onChange={handleInputChange}
                className="mt-1 p-3 block w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={userInfo.last_name || ""}
                onChange={handleInputChange}
                className="mt-1 p-3 block w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={userInfo.email || ""}
                onChange={handleInputChange}
                className="mt-1 p-3 block w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                readOnly
              />
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone_number"
                value={userInfo.phone_number || ""}
                onChange={handleInputChange}
                className="mt-1 p-3 block w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div className="mt-6">
            {/* <label className="block text-sm font-medium text-gray-700">
              Profile Picture
            </label>
            <div className="flex items-center space-x-4 mt-2">
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="w-24 h-24 rounded-full shadow-md"
                />
              ) : (
                <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="p-2 border border-gray-300 rounded-lg"
              />
            </div> */}
          </div>

          <button
            type="submit"
            className="w-full py-3 mt-4 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Update Information
          </button>

          {updateSuccess && (
            <p className="mt-4 text-green-500">{updateSuccess}</p>
          )}
        </form>

        {/* Order History */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Order History</h2>
          {loadingOrders ? (
            <p>Loading order history...</p>
          ) : orderError ? (
            <p>{orderError}</p>
          ) : orderHistory.length > 0 ? (
            <>
              <ul className="space-y-4">
                {currentOrders.map((order) => (
                  <li
                    key={order.id}
                    className="p-4 border border-gray-200 rounded-lg shadow-sm"
                  >
                    <p className="text-lg font-semibold">Order #{order.id}</p>
                    <p className="text-sm text-gray-500">
                      Date: {new Date(order.created_at).toLocaleDateString()}
                    </p>
                    <p className="text-sm text-gray-500">
                      Total: ${order.total_amount}
                    </p>
                    <h4 className="mt-2 font-medium">Items:</h4>
                    <ul className="pl-4 list-disc text-gray-600">
                      {order.items.map((item) => (
                        <li key={item.id}>
                          {item.quantity} x {item.product_name} - $
                          {item.total_price}
                        </li>
                      ))}
                    </ul>
                    {/* Order Again Button */}
                    <button
                      onClick={() => handleOrderAgain(order.id)}
                      className="mt-4 bg-black text-white py-2 px-4 rounded-lg hover:scale-105 transition duration-200"
                    >
                      Order Again
                    </button>
                  </li>
                ))}
              </ul>

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="mt-6 flex justify-center">
                  <Pagination className="flex justify-center items-center space-x-2">
                    <PaginationPrevious
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="px-3 py-1 bg-gray-200 rounded-lg hover:bg-gray-300 transition"
                    >
                      Previous
                    </PaginationPrevious>

                    <PaginationContent>
                      {/* Add an ellipsis at the start if there are more pages before the displayed range */}
                      {currentPage > Math.floor(pageWindowSize / 2) + 1 && (
                        <PaginationItem>
                          <span className="px-3 py-1">...</span>
                        </PaginationItem>
                      )}

                      {/* Display only the limited range of pages */}
                      {getPaginationRange().map((page) => (
                        <PaginationItem key={page}>
                          <PaginationLink
                            isActive={currentPage === page}
                            onClick={() => handlePageChange(page)}
                            className={`px-3 py-1 ${
                              currentPage === page
                                ? "bg-black text-white"
                                : "bg-gray-200 text-gray-700"
                            } rounded-lg hover:scale-105 transition duration-300`}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      {/* Add an ellipsis at the end if there are more pages after the displayed range */}
                      {currentPage <
                        totalPages - Math.floor(pageWindowSize / 2) && (
                        <PaginationItem>
                          <span className="px-3 py-1">...</span>
                        </PaginationItem>
                      )}
                    </PaginationContent>

                    <PaginationNext
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-3 py-1 bg-gray-200 rounded-lg hover:bg-gray-300 transition"
                    >
                      Next
                    </PaginationNext>
                  </Pagination>
                </div>
              )}
            </>
          ) : (
            <p>No orders found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
