import React, { useState, useEffect } from "react";
import CartButton from "./CartButton";
import { useParams } from "react-router-dom";
import { addToCart } from "../utility/CartUtils"; // Import the shared addToCart function
import axiosInstance from "../../api/api";
import ImageGallery from "react-image-gallery"; // Import the library
import "react-image-gallery/styles/css/image-gallery.css"; // Import default styles

const ProductDetails = ({ updateCartItemCount, cartItemCount }) => {
  const { productId } = useParams(); // Get product ID from route
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1); // Default quantity is 1
  const [selectedVariation, setSelectedVariation] = useState(""); // State for selected variation

  // Fetch the product data from the API when component mounts
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axiosInstance.get(
          `inventory/products/${productId}`
        );
        setProduct(response.data); // Set product data
        // Set the default selected variation (if available)
        if (response.data.sub_names && response.data.sub_names.length > 0) {
          setSelectedVariation(response.data.sub_names[0]); // Default to the first variation
        }
      } catch (error) {
        console.error("Failed to fetch product:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  // Fetch the latest cart count when the component mounts
  useEffect(() => {
    updateCartItemCount(); // Ensure cart count is synced when the component mounts
  }, [updateCartItemCount]);

  const handleAddToCart = async () => {
    await addToCart(product.id, quantity, updateCartItemCount, selectedVariation); // Pass selected variation
    setQuantity(1); // Reset quantity to 1 after adding to cart
  };

  if (!product) {
    return <p>Loading product details...</p>;
  }

  // Prepare the images array for react-image-gallery
  const images =
    product.images && product.images.length > 0
      ? product.images.map((image) => ({
          original: image.image_file ? image.image_file : image.image_url,
          thumbnail: image.image_file ? image.image_file : image.image_url,
        }))
      : [
          {
            original: "fallback-image-url.jpg",
            thumbnail: "fallback-image-url.jpg",
          },
        ];

  return (
    <div className="min-h-screen flex flex-col justify-between">
      {/* Pass cartItemCount to CartButton */}
      <CartButton
        cartItemCount={cartItemCount}
        updateCartItemCount={updateCartItemCount}
      />

      <div className="flex-grow flex items-center justify-center p-4">
        <div className="flex flex-col md:flex-row max-w-6xl w-full">
          {/* Left side - Product Image or Gallery */}
          <div className="w-full md:w-1/2 p-4 md:pr-12">
            {images.length > 0 ? (
              <ImageGallery
                items={images}
                showThumbnails={true}
                showFullscreenButton={false}
                showPlayButton={false}
              />
            ) : (
              <img
                src="fallback-image-url.jpg" // Optional fallback image
                alt={product.name}
                className="w-full h-auto object-cover rounded-md"
              />
            )}
          </div>

          {/* Right side - Product details */}
          <div className="w-full md:w-1/2 p-4">
            <h3 className="text-3xl font-semibold mb-2 text-gray-800">
              {product.name}
            </h3>
            {product.sub_name && (
              <h4 className="text-xl font-medium mb-2 text-gray-600">
                {product.sub_name}
              </h4>
            )}
            <p className="text-3xl font-bold text-gray-900 mb-6">
              ${product.price}
            </p>

            {/* Dropdown for Variations */}
            {product.sub_names && product.sub_names.length > 0 && (
              <div className="mb-4">
                <label
                  htmlFor="variation-dropdown"
                  className="block text-gray-600 text-sm mb-2"
                >
                  Choose a variation:
                </label>
                <select
                  id="variation-dropdown"
                  value={selectedVariation}
                  onChange={(e) => setSelectedVariation(e.target.value)}
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                >
                  {product.sub_names.map((variation, index) => (
                    <option key={index} value={variation}>
                      {variation}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Quantity controls */}
            <div className="flex items-center justify-start mb-6">
              <button
                className="bg-gray-300 px-3 py-2 rounded text-lg"
                onClick={() => setQuantity(quantity - 1)}
                disabled={quantity <= 1}
              >
                -
              </button>
              <span className="mx-4 text-xl">{quantity}</span>
              <button
                className="bg-gray-300 px-3 py-2 rounded text-lg"
                onClick={() => setQuantity(quantity + 1)}
              >
                +
              </button>
            </div>

            {/* Add to Cart Button */}
            <button
              className="bg-black text-white px-6 py-3 rounded-lg mb-6"
              onClick={() => {
                if (product.quantity_on_hand > 0) handleAddToCart(); // Only allow click if the product is available
              }}
            >
              Add to Cart
            </button>

            {/* Product Description */}
            <p className="text-gray-700 leading-relaxed mb-2">
              {product.description}
            </p>
            <p className="text-gray-700 leading-relaxed">
              {product.additionalInfo}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
