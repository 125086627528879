import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Cards from "./components/Cards";
import Location from "./components/Location";
import Login from "./components/users/Login";
import Register from "./components/users/Register";
import Dashboard from "./components/users/Dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./globals.css";
import ProductPage from "./components/products/ProductPage";
import CheckoutPage from "./components/checkout/CheckoutPage";
import ProductDetails from "./components/products/ProductDetails";
import useCart from "./components/utility/CartUtils";
import ConfirmationPage from "./components/checkout/ConfirmationPage";
import AgeVerification from "./components/AgeVerification";
import { ProtectedRoute } from "./components/utility/ProtectedRoute";

function App() {
	const { cartItemCount, updateCartItemCount } = useCart();

	useEffect(() => {
		const handleAnchorClick = (event) => {
			const target = event.target.closest('a[href^="#"]');
			if (target) {
				const id = target.getAttribute("href").slice(1);
				const element = document.getElementById(id);
				if (element) {
					event.preventDefault();
					const offsetTop = element.offsetTop - 70; // Adjust based on your fixed header height
					window.scrollTo({
						top: offsetTop,
						behavior: "smooth",
					});
				}
			}
		};

		document.addEventListener("click", handleAnchorClick);
		return () => {
			document.removeEventListener("click", handleAnchorClick);
		};
	}, []);
	return (
		<Router>
			<div className="App min-h-screen flex flex-col">
				{/* Navbar and main content should be inside the flex-grow container */}
				<AgeVerification/>
				<div className="flex-grow">
					<Routes>
						<Route
							path="/"
							element={
								<>
									<Navbar />
									<Hero />
									<Cards />
									<Location />
								</>
							}
						/>
						<Route
							path="/products"
							element={
								
									<ProtectedRoute><ProductPage /></ProtectedRoute>
								
							}
						/>
						<Route
							path="/product/:productId"
							element={
								<>
									<ProductDetails
										cartItemCount={cartItemCount}
										updateCartItemCount={updateCartItemCount}
									/>
								</>
							}
						/>
						<Route
							path="/login"
							element={
								<>
									<Login />
								</>
							}
						/>
						<Route
							path="/dashboard"
							element={
								<>
									<Dashboard />
								</>
							}
						/>
						<Route
							path="/register"
							element={
								<>
									<Register />
								</>
							}
						/>
						<Route
							path="/checkout"
							element={
								<>
									<CheckoutPage />
								</>
							}
						/>
						<Route
							path="/confirmation"
							element={
								<>
									<ConfirmationPage />
								</>
							}
						/>
					</Routes>
				</div>

				{/* Footer stays outside flex-grow */}
				<Footer />
			</div>
		</Router>
	);
}

export default App;
