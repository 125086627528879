import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { checkAuth } from "../../api/api";

export const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const verifyAuth = async () => {
      const authStatus = await checkAuth();
      setIsAuthenticated(authStatus === "authenticated");
    };
    verifyAuth();
  }, []);

  if (isAuthenticated === null) {
    return null; // You can render a loading spinner here if desired
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};
