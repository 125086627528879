import React from "react";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";
import { buttonVariants } from "./Button";
// Helper function to handle class names (assuming you have this function implemented)
const cn = (...classes) => classes.filter(Boolean).join(" ");

// Pagination component
const Pagination = ({ className, ...props }) => (
	<nav
		role="navigation"
		aria-label="pagination"
		className={cn("mx-auto flex w-full justify-center", className)}
		{...props}
	/>
);

// Pagination Content
const PaginationContent = React.forwardRef(({ className, ...props }, ref) => (
	<ul
		ref={ref}
		className={cn("flex flex-row items-center gap-1", className)}
		{...props}
	/>
));

// Pagination Item
const PaginationItem = React.forwardRef(({ className, ...props }, ref) => (
	<li
		ref={ref}
		className={cn("", className)}
		{...props}
	/>
));

// Pagination Link
const PaginationLink = ({ className, isActive, size = "icon", ...props }) => (
	<a
		aria-current={isActive ? "page" : undefined}
		className={cn(
			buttonVariants({
				variant: isActive ? "outline" : "ghost",
				size,
			}),
			className
		)}
		{...props}
	/>
);

// Pagination Previous Button
const PaginationPrevious = ({ className, ...props }) => (
	<PaginationLink
		aria-label="Go to previous page"
		size="default"
		className={cn("gap-1 pl-2.5", className)}
		{...props}
	>
		<ChevronLeft className="h-4 w-4" />
		<span>Previous</span>
	</PaginationLink>
);

// Pagination Next Button
const PaginationNext = ({ className, ...props }) => (
	<PaginationLink
		aria-label="Go to next page"
		size="default"
		className={cn("gap-1 pr-2.5", className)}
		{...props}
	>
		<span>Next</span>
		<ChevronRight className="h-4 w-4" />
	</PaginationLink>
);

// Pagination Ellipsis
const PaginationEllipsis = ({ className, ...props }) => (
	<span
		aria-hidden
		className={cn("flex h-9 w-9 items-center justify-center", className)}
		{...props}
	>
		<MoreHorizontal className="h-4 w-4" />
		<span className="sr-only">More pages</span>
	</span>
);

export {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
};
