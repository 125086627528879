import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../api/api"; // Adjust the import path
import Image1 from "../assets/geek.jpg"; // Replace with your actual image paths
import Image2 from "../assets/vape-mod.jpg"; // Replace with your actual image paths
import Image3 from "../assets/cigar.jpg"; // Replace with your actual image paths

const Cards = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showDialog, setShowDialog] = useState(false); // State to handle popup visibility
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserAuth = async () => {
      const status = await checkAuth();
      if (status === "authenticated") {
        setIsAuthenticated(true);
      }
    };
    checkUserAuth();
  }, []);

  const handleExploreClick = () => {
    if (isAuthenticated) {
      navigate("/products");
    } else {
      setShowDialog(true); // Show the dialog if not authenticated
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <div
      id="explore"
      className="w-full bg-gray-100 py-12 flex flex-col justify-center items-center"
    >
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800">
          Explore Our Products
        </h1>
        <p className="text-lg text-gray-600 mt-4">
          Discover a wide range of products, crafted for your unique experience.
        </p>
      </div>

      {/* Cards Section */}
      <div className="flex flex-wrap justify-center items-center max-w-7xl gap-8">
        {/* Card 1 */}
        <div className="w-80 h-96 bg-white rounded-lg overflow-hidden shadow-lg relative">
          <img
            src={Image1}
            alt="Product 1"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>

        {/* Card 2 - Slightly Bigger & Popped Out */}
        <div className="w-96 h-[28rem] bg-white rounded-lg overflow-hidden shadow-2xl relative transform scale-105">
          <img
            src={Image2}
            alt="Product 2"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>

        {/* Card 3 */}
        <div className="w-80 h-96 bg-white rounded-lg overflow-hidden shadow-lg relative">
          <img
            src={Image3}
            alt="Product 3"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
      </div>

      {/* Button Section */}
      <div className="mt-12">
        <button
          className="bg-black text-white py-3 px-8 rounded-full text-lg border-2 border-transparent hover:bg-white hover:text-black hover:border-black transform hover:scale-105 transition-all duration-300"
          onClick={handleExploreClick}
        >
          Explore Products
        </button>
      </div>

      {/* Popup Dialog */}
      {showDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">Login Required</h2>
            <p className="mb-4">
              You must be logged in to view the products page.
            </p>
            <button
              className="bg-black text-white py-2 px-4 rounded hover:scale-105 transition duration-300"
              onClick={closeDialog}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;
