import React from "react";
import BackgroundImage from "../assets/smoke.jpg"; // Replace with your actual image path

const Location = () => {
	return (
		<div
			id="contact"
			className="w-full py-16 text-black px-4 bg-cover bg-center relative"
			style={{
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			{/* Overlay to darken the background for better text visibility */}
			<div className="bg-black bg-opacity-50 w-full h-full absolute inset-0"></div>

			{/* Content Container with Background Behind Columns */}
			<div className="relative z-10 max-w-[1240px] mx-auto bg-gray-200 bg-opacity-80 rounded-lg p-8 shadow-lg">
				{/* Contact Us Heading */}
				<div className="text-center mb-8">
					<h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-black">
						Contact Us
					</h1>
				</div>

				{/* Columns for Contact Info and Google Maps */}
				<div className="grid md:grid-cols-2 gap-4 text-black">
					{/* Left Column: Contact Info */}
					<div className="flex flex-col justify-center">
						<p className="py-2 text-lg">
							<span className="font-bold">Address:</span> 640 29th ave SW Cedar
							Rapids, Iowa, 52404
						</p>
						<p className="py-2 text-lg">
							<span className="font-bold">Phone:</span> (319) 200-3254
						</p>
						<p className="py-2 text-lg">
							<span className="font-bold">Email:</span> mnkdistro@gmail.com
						</p>
						<p className="py-2 text-lg">
							<span className="font-bold">Working Hours:</span> 8:00 am - 6:00
							pm
						</p>
					</div>

					{/* Right Column: Google Maps Pin */}
					<div className="relative w-full h-[300px] transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl">
						{/* Clickable Overlay */}
						<a
							href="https://maps.app.goo.gl/oYNMSgz5r617zmhq8"
							target="_blank"
							rel="noopener noreferrer"
							className="absolute inset-0 z-10"
							aria-label="Open location on Google Maps"
						></a>

						{/* Google Maps iframe */}
						<iframe
							title="location"
							className="w-full h-full rounded-md shadow-lg"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.376253097528!2d-91.6786715!3d41.9492528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87e4f9d4fdd38cb5%3A0xd0dd30db9e772459!2s640%2029th%20Ave%20SW%2C%20Cedar%20Rapids%2C%20IA%2052404!5e0!3m2!1sen!2sus!4v1729586044944!5m2!1sen!2sus"
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Location;
