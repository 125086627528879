import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance, { checkAuth, clearTokensAndLogout } from "../../api/api";
import Banner from "../ui/Banner";
import { fetchCartItems } from "../utility/CartUtils";

const CheckoutPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postal_code: "",
    state: "",
    country: "",
  });
  const [deliveryMethod, setDeliveryMethod] = useState("pickup");
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [shippingCost, setShippingCost] = useState(0);
  const addressInputRef = useRef(null);
  const [paymentFee, setPaymentFee] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const calculateShippingCost = async (customerAddress) => {
    return 30;
  };

  const loadGoogleMapsScript = () => {
    if (!document.querySelector('script[src*="maps.googleapis.com"]')) {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCSj2BjL6F3ZQE-_mU9OdCdbMBA06KupFE&libraries=places";
      script.async = true;
      script.onload = initializeAutocomplete;
      document.body.appendChild(script);
    }
  };

  const initializeAutocomplete = () => {
    if (window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        { types: ["address"], componentRestrictions: { country: "us" } }
      );

      // Event listener for place selection
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address && place.address_components) {
          const addressComponents = place.address_components;

          const getAddressComponent = (type) => {
            const component = addressComponents.find((comp) =>
              comp.types.includes(type)
            );
            return component ? component.long_name : "";
          };

          // Get the full address and break it down into components
          const fullAddress = place.formatted_address;

          // Update only the address fields, keeping the rest of the form data intact
          setFormData((prevFormData) => ({
            ...prevFormData, // Keep existing form data
            address: `${getAddressComponent(
              "street_number"
            )} ${getAddressComponent("route")}`,
            city: getAddressComponent("locality"),
            state: getAddressComponent("administrative_area_level_1"),
            postal_code: getAddressComponent("postal_code"),
            country: getAddressComponent("country"),
          }));

          // Optional: If you need to calculate shipping cost based on the address
          const cost = await calculateShippingCost(fullAddress);
          setShippingCost(cost);
        }
      });
    }
  };

  // Load Google Maps script on component mount
  useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  // Fetch cart items
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCartItems(
          setCartItems,
          (totalItems) => {},
          () => {}
        );
        const total = cartItems.reduce(
          (sum, item) => sum + item.price * item.quantity,
          0
        );
        setTotalPrice(total);
      } catch (error) {
        console.error("Error loading cart items:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch user profile data to auto-fill the form
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const authStatus = await checkAuth();
        if (authStatus === "authenticated") {
          const response = await axiosInstance.get("users/profile/");
          const {
            first_name,
            last_name,
            email,
            phone_number,
            billing_address,
            city,
            postal_code,
            state,
            country,
          } = response.data;

          setFormData((prevFormData) => ({
            ...prevFormData,
            first_name: first_name || "",
            last_name: last_name || "",
            email: email || "",
            phone: phone_number || "",
            address: billing_address || "",
            city: city || "",
            postal_code: postal_code || "",
            state: state || "",
            country: country || "",
          }));
        } else {
          console.warn("User is not authenticated. Redirecting to login.");
          clearTokensAndLogout();
        }
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateStripeFee = (amount, method) => {
    if (method === "stripe") {
      const stripePercentage = 0.029; // 2.9% Stripe fee
      const fixedFee = 0.3; // $0.30 fixed fee
      // Gross-up formula
      const grossAmount = (amount + fixedFee) / (1 - stripePercentage);
      return (grossAmount - amount).toFixed(2); // Returns only the fee amount
    } else if (method === "bank") {
      const bankFee = Math.min(amount * 0.008, 5); // Bank fee: 0.8% capped at $5.00
      return bankFee.toFixed(2);
    }
    return 0;
  };

  useEffect(() => {
    if (cartItems.length > 0) {
      const baseTotal =
        cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0) +
        (deliveryMethod === "shipping" ? parseFloat(shippingCost) : 0);

      const fee = calculateStripeFee(baseTotal, paymentMethod);
      setPaymentFee(parseFloat(fee).toFixed(2));

      const finalTotalWithFee = (
        parseFloat(baseTotal) + parseFloat(fee)
      ).toFixed(2);
      setTotalPrice(baseTotal.toFixed(2)); // Set subtotal
      setFinalTotal(finalTotalWithFee); // Set final total
    }
  }, [cartItems, shippingCost, deliveryMethod, paymentMethod]);

  // Handle delivery method change
  const handleDeliveryMethodChange = async (e) => {
    const selectedMethod = e.target.value;
    setDeliveryMethod(selectedMethod);

    if (selectedMethod === "shipping") {
      const cost = await calculateShippingCost(formData.address);
      setShippingCost(cost);
    } else {
      setShippingCost(0); // Reset shipping cost to zero for pickup
    }
  };

  const handlePaymentMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);

    // Calculate and set the Stripe fee based on the selected method
    const fee = calculateStripeFee(totalPrice, selectedMethod);
    setPaymentFee(fee);

    // Enforce pickup only if cash is selected
    if (selectedMethod === "cash") {
      setDeliveryMethod("pickup");
    }
  };

  // Handle checkout submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const authStatus = await checkAuth();
      if (authStatus === "authenticated") {
        const endpoint =
          paymentMethod === "cash" ? "checkout-cash/" : "checkout/";
        const orderResponse = await axiosInstance.post(endpoint, {
          ...formData,
          delivery_method: deliveryMethod,
          payment_method: paymentMethod,
        });

        const orderId = orderResponse.data.order_id;

        if (paymentMethod === "cash") {
          // Redirect to confirmation page with orderId in query parameters for cash payments
          navigate(`/confirmation?orderId=${orderId}`);
        } else {
          // Prepare line items for Stripe session
          const lineItems = cartItems.map((item) => ({
            name: item.product_name,
            price: item.price,
            quantity: item.quantity,
            currency: "usd",
          }));

          // Add shipping fee if delivery method is "shipping"
          const shippingFee = deliveryMethod === "shipping" ? shippingCost : 0;

          if (deliveryMethod === "shipping") {
            lineItems.push({
              name: "Shipping Fee",
              price: shippingCost,
              quantity: 1,
              currency: "usd",
            });
          }

          // Add payment fee based on the selected payment method
          if (paymentMethod === "stripe") {
            lineItems.push({
              name: "Payment Fee (Card)",
              price: parseFloat(paymentFee),
              quantity: 1,
              currency: "usd",
            });
          } else if (paymentMethod === "bank") {
            lineItems.push({
              name: "Payment Fee (Bank)",
              price: parseFloat(paymentFee),
              quantity: 1,
              currency: "usd",
            });
          }

          // Call backend to create Stripe session
          const paymentSessionResponse = await axiosInstance.post(
            "payments/payment-session/",
            {
              order_id: orderId,
              product_data: lineItems,
              shipping_option: deliveryMethod,
              shipping_fee: shippingFee,
              payment_fee: parseFloat(paymentFee),
              selected_payment_method: paymentMethod,
            }
          );

          window.location.href = paymentSessionResponse.data.url;
        }
      } else {
        console.warn("User is not authenticated. Redirecting to login.");
        clearTokensAndLogout();
      }
    } catch (error) {
      console.error("Checkout failed:", error);
      alert("Failed to place the order. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Banner />
      <div className="checkout-container mx-auto px-6 py-8 max-w-5xl">
        <div className="checkout-content grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Checkout Form */}
          <div className="lg:col-span-2 bg-white p-8 rounded-md shadow-lg">
            <h2 className="text-3xl font-bold mb-6">Checkout</h2>

            <form onSubmit={handleSubmit} className="space-y-8">
              {/* Delivery Method */}
              <fieldset>
                <legend className="text-lg font-bold mb-4">
                  Delivery Method
                </legend>
                <div className="flex gap-4">
                  <button
                    type="button"
                    onClick={() => setDeliveryMethod("pickup")}
                    className={`flex-1 flex items-center justify-center p-4 border rounded-md ${
                      deliveryMethod === "pickup"
                        ? "border-black"
                        : "border-gray-300"
                    }`}
                  >
                    <span className="mr-2 text-lg">📍</span> Local Pickup
                  </button>
                  <button
                    type="button"
                    onClick={() => setDeliveryMethod("shipping")}
                    className={`flex-1 flex items-center justify-center p-4 border rounded-md ${
                      deliveryMethod === "shipping"
                        ? "border-black"
                        : "border-gray-300"
                    }`}
                  >
                    <span className="mr-2 text-lg">🚚</span> Shipping
                  </button>
                </div>
              </fieldset>

              {/* Payment Method */}
              <fieldset>
                <legend className="text-lg font-bold mb-4">
                  Payment Method
                </legend>
                <div className="flex flex-wrap gap-4">
                  {[
                    { label: "Card", value: "stripe" },
                    { label: "Bank Transfer", value: "bank" },
                    { label: "Cash", value: "cash" },
                  ].map((method) => (
                    <label
                      key={method.value}
                      className={`flex-1 p-4 border rounded-md flex items-center ${
                        paymentMethod === method.value
                          ? "border-black"
                          : "border-gray-300"
                      }`}
                    >
                      <input
                        type="radio"
                        name="payment_method"
                        value={method.value}
                        checked={paymentMethod === method.value}
                        onChange={() => setPaymentMethod(method.value)}
                        className="mr-2"
                      />
                      {method.label}
                    </label>
                  ))}
                </div>
              </fieldset>

              {/* Customer Information */}
              <fieldset>
                <legend className="text-lg font-bold mb-4">
                  Customer Information
                </legend>
                <div className="space-y-4">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-md"
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-md"
                    />
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-md"
                    />
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-md"
                  />
                </div>
              </fieldset>

              {/* Billing Information */}
              <fieldset>
                <legend className="text-lg font-bold mb-4">
                  Shipping Address
                </legend>
                <input
                  type="text"
                  name="address"
                  ref={addressInputRef}
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border border-gray-300 rounded-md mb-4"
                />
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    name="state"
                    placeholder="State"
                    value={formData.state}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="postal_code"
                    placeholder="Postal Code"
                    value={formData.postal_code}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-md"
                  />
                </div>
              </fieldset>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-black text-white p-3 rounded-md font-bold hover:bg-white hover:text-black hover:scale-105 hover:duration-300 hover:border transition"
              >
                {isSubmitting ? "Placing Order..." : "Place Order"}
              </button>
            </form>
          </div>

          {/* Order Summary */}
          <div className="bg-white p-4 shadow-md rounded-md">
            <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
            {cartItems.length === 0 ? (
              <p>Your cart is empty.</p>
            ) : (
              <>
                <ul className="mb-4 overflow-y-auto max-h-[600px]">
                  {cartItems.map((item) => (
                    <li
                      key={item.id}
                      className="flex items-center justify-between mb-4"
                    >
                      <div className="flex items-center">
                        <img
                          src={item.image_url}
                          alt={item.product_name}
                          className="w-16 h-16 object-cover rounded-md mr-4"
                        />
                        <div>
                          <p className="text-sm font-medium">
                            {item.product_name}
                          </p>
                          <p className="text-xs text-gray-500">
                            {item.quantity} x $
                            {parseFloat(item.price).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <p className="text-sm font-semibold">
                        ${parseFloat(item.price * item.quantity).toFixed(2)}
                      </p>
                    </li>
                  ))}
                </ul>
                <div className="border-t border-gray-300 pt-4">
                  <div className="flex justify-between mb-2">
                    <span className="text-sm text-gray-600">Subtotal</span>
                    <span className="text-sm font-medium">
                      ${parseFloat(totalPrice).toFixed(2)}
                    </span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className="text-sm text-gray-600">Delivery</span>
                    {deliveryMethod === "shipping" ? (
                      <span className="text-sm font-medium">
                        ${shippingCost || 0.0}
                      </span>
                    ) : (
                      <span className="text-sm font-medium text-black">
                        FREE
                      </span>
                    )}
                  </div>
                  {deliveryMethod === "shipping" && (
                    <div className="text-xs text-gray-500 mb-4">
                      Shipping to: {formData.address}, {formData.city},{" "}
                      {formData.state} {formData.postal_code}
                    </div>
                  )}
                  <div className="flex justify-between mb-2">
                    <span className="text-sm text-gray-600">Payment Fee</span>
                    <span className="text-sm font-medium">
                      ${paymentFee || 0.0}
                    </span>
                  </div>
                  <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                    <span className="text-lg font-semibold">Total</span>
                    <span className="text-lg font-semibold">
                      ${parseFloat(finalTotal || 0.0).toFixed(2)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;
