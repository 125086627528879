import axios from "axios";

// Base API URL
const apiUrl = "https://admin.mnkdistro.com/api/";
// const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

// Axios instance
const axiosInstance = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true, // Ensure cookies (access and refresh tokens) are sent with each request
});

// Utility function to clear tokens and log out the user
export const clearTokensAndLogout = () => {
	axiosInstance.post("users/logout/");
	window.location.href = "/login";
};

// Function to refresh the token
export const try_refresh = async () => {
	try {
		const response = await axiosInstance.post("users/token/refresh/");
		if (response.data.message === "Guest access allowed") {
			return "guest"; // Return 'guest' to indicate guest access
		} else {
			return "authenticated"; // Return 'authenticated' to indicate user is logged in
		}
	} catch (error) {
		if (error.response && error.response.status === 400) {
			return "no-refresh-token"; // Return a specific value indicating no refresh token
		} else if (error.response && error.response.status === 401) {
			return "session-expired"; // Return a specific value indicating session expired
		} else {
			return "error"; // Return a generic error value
		}
	}
};

// Function to check authentication using the /auth endpoint
export const checkAuth = async () => {
	try {
		const response = await axiosInstance.get("users/auth/");
		return response.data.isAuthenticated ? "authenticated" : "guest";
	} catch (error) {
		if (error.response && error.response.status === 401) {
			// Attempt to refresh the token if not authenticated
			return await try_refresh();
		} else {
			return "error"; // Return a generic error value
		}
	}
};

let isRefreshing = false; // Flag to check if the token is currently being refreshed
let refreshSubscribers = []; // List of subscribers waiting for token refresh

// Function to add subscribers (requests) to the queue
const subscribeTokenRefresh = (callback) => {
	refreshSubscribers.push(callback);
};

// Function to notify all subscribers once the token refresh is complete
const onRefreshed = (newAccessToken) => {
	refreshSubscribers.forEach((callback) => callback(newAccessToken));
	refreshSubscribers = []; // Clear the queue after notifying all subscribers
};

axiosInstance.interceptors.response.use(
	(response) => response, // Pass successful responses through
	async (error) => {
		const originalRequest = error.config;

		// If the error is a 401 (Unauthorized) and not related to account approval
		if (
			error.response &&
			error.response.status === 401 &&
			!originalRequest._retry &&
			error.response.data.code !== "authentication_failed" // Check for specific error code
		) {
			originalRequest._retry = true;

			if (isRefreshing) {
				// If a token refresh is already in progress, queue the request
				return new Promise((resolve) => {
					subscribeTokenRefresh((newAccessToken) => {
						originalRequest.headers[
							"Authorization"
						] = `Bearer ${newAccessToken}`;
						resolve(axiosInstance(originalRequest));
					});
				});
			}

			isRefreshing = true; // Set the refresh lock to prevent multiple refresh attempts
			try {
				const response = await try_refresh();

				if (response === "authenticated") {
					onRefreshed(response);
					originalRequest.headers["Authorization"] = `Bearer ${response}`;
					return axiosInstance(originalRequest);
				} else if (response === "guest") {
					onRefreshed(null);
					return Promise.reject(error);
				} else {
					clearTokensAndLogout();
					return Promise.reject(error);
				}
			} catch (err) {
				clearTokensAndLogout();
				return Promise.reject(error);
			} finally {
				isRefreshing = false;
			}
		}

		// Pass through other errors, like the "account not approved" error
		return Promise.reject(error);
	}
);

// Example function to fetch products
export const fetchProducts = async () => {
	try {
		const response = await axiosInstance.get("inventory/products/");
		return response.data;
	} catch (error) {
		console.error("Error fetching products", error);
		throw error;
	}
};

export default axiosInstance;
