import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../api/api"; // Adjust the import path
import Background from "../assets/smoke-vid.mp4";

const Hero = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showDialog, setShowDialog] = useState(false); // State to handle popup visibility
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserAuth = async () => {
      const status = await checkAuth();
      if (status === "authenticated") {
        setIsAuthenticated(true);
      }
    };
    checkUserAuth();
  }, []);

  const handleExploreClick = () => {
    if (isAuthenticated) {
      navigate("/products");
    } else {
      setShowDialog(true); // Show the dialog if not authenticated
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <div id="home" className="relative h-screen w-full">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src={Background} type="video/mp4" />
      </video>
      {/* Content */}
      <div className="relative z-10 max-w-[800px] mt-0 w-full h-screen mx-auto text-center flex flex-col justify-center">
        <h1 className="md:text-7xl text-white sm:text-6xl text-4xl font-bold md:py-6">
          MNK DISTRO
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-3xl sm:text-4xl text-xl font-bold py-4 text-white">
            The one-stop shop for all of your tobacco needs
          </p>
        </div>
        <p className="md:text-2xl text-xl font-bold text-white"></p>
        <button
          className="bg-white border-2 border-transparent w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black transition-transform transform duration-300 ease-in-out hover:border-slate-700 hover:scale-105"
          onClick={handleExploreClick}
        >
          Explore
        </button>
      </div>

      {/* Popup Dialog */}
      {showDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">Login Required</h2>
            <p className="mb-4">
              You must be logged in to view the products page.
            </p>
            <button
              className="bg-black text-white py-2 px-4 rounded hover:scale-105 transition duration-300"
              onClick={closeDialog}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
