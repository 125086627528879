import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Banner from "../ui/Banner";
import axiosInstance, { checkAuth, clearTokensAndLogout } from "../../api/api";

const ConfirmationPage = () => {
	const [orderDetails, setOrderDetails] = useState(null);
	const [customerName, setCustomerName] = useState("");
	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const orderId = queryParams.get("orderId"); // Get orderId from URL query parameters

	useEffect(() => {
		if (!orderId) {
			console.error("No orderId found.");
			return;
		}

		const fetchOrderDetails = async () => {
			try {
				const authStatus = await checkAuth();
				if (authStatus === "authenticated") {
					const response = await axiosInstance.get(`orders/${orderId}/`);
					setOrderDetails(response.data);
					const profileResponse = await axiosInstance.get("users/profile/");
					setCustomerName(profileResponse.data.first_name);
				} else {
					clearTokensAndLogout();
				}
			} catch (error) {
				console.error("Failed to fetch order details or customer name:", error);
			}
		};

		fetchOrderDetails();
	}, [orderId]);

	if (!orderDetails) {
		return <p>Loading order details...</p>;
	}

	return (
		<div>
			<Banner />
			<div className="flex flex-col items-center justify-center min-h-[795px] p-6 text-center">
				<h1 className="text-2xl font-bold mb-6">
					Thank you for your order, {customerName}!
				</h1>
				<p className="mb-4">
					We appreciate your purchase. You will receive an email regarding the status of your order.
				</p>
				<button
					onClick={() => navigate("/")}
					className="bg-black hover:scale-105 transition duration-300 text-white font-bold py-2 px-4 rounded"
				>
					Back to Home
				</button>
			</div>
		</div>
	);
};

export default ConfirmationPage;
