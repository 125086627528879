import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../ui/Banner";
import {
  fetchCartItems,
  removeItemFromCart,
  addToCart,
} from "../utility/CartUtils";
import { FaShoppingCart } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const CartButton = ({ updateCartItemCount, cartItemCount }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const handleCheckout = () => {
    setIsCartOpen(false);
    navigate("/checkout");
  };

  const increaseQuantity = async (item) => {
    await addToCart(item.product_id, 1, updateCartItemCount);
    fetchCartItems(setCartItems, updateCartItemCount, setIsLoading);
  };

  const decreaseQuantity = async (item) => {
    if (item.quantity > 1) {
      await addToCart(item.product_id, -1, updateCartItemCount);
      fetchCartItems(setCartItems, updateCartItemCount, setIsLoading);
    } else {
      removeItemFromCart(item.id, () =>
        fetchCartItems(setCartItems, updateCartItemCount, setIsLoading)
      );
    }
  };

  useEffect(() => {
    if (isCartOpen) {
      fetchCartItems(setCartItems, updateCartItemCount, setIsLoading);
    }
  }, [isCartOpen]);

  return (
    <div className="relative">
      <Banner />

      {/* Cart button with FaShoppingCart icon */}
      <button
        className="absolute z-50 top-0 right-0 m-4 p-2 bg-transparent text-white rounded-lg flex items-center space-x-2"
        onClick={toggleCart}
      >
        <FaShoppingCart size={20} />
        <span>({cartItemCount})</span>
      </button>

      {/* Tint Overlay */}
      {isCartOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={toggleCart} // Close the cart when clicking outside
        />
      )}

      {/* Sliding Cart Menu */}
      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-lg z-50 transform ${
          isCartOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out w-[30rem]`}
      >
        {/* Close Button */}
        <button
          className="absolute top-4 left-4 text-gray-600 hover:text-gray-800"
          onClick={toggleCart}
        >
          <IoClose size={24} />
        </button>

        {/* Cart Title */}
        <h2 className="text-2xl font-bold text-center my-4">Your Cart</h2>

        {/* Cart Items */}
        {isLoading ? (
          <p className="text-center">Loading cart...</p>
        ) : cartItems.length === 0 ? (
          <p className="text-center">Your cart is empty</p>
        ) : (
          <div className="px-4 flex-grow max-h-[800px] overflow-y-auto">
            <ul>
              {cartItems.map((item, index) => (
                <li
                  key={item.id}
                  className="mb-4 flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow-sm drop-shadow-lg" // Consistent classes
                >
                  {/* Product Image */}
                  <img
                    src={item.image_url}
                    alt={item.product_name}
                    className="w-16 h-16 object-cover rounded-md mr-4"
                  />

                  {/* Product Details */}
                  <div className="flex-grow">
                    <div className="text-lg font-semibold">
                      {item.product_name}
                    </div>
                    <div className="text-sm text-gray-600">
                      {item.quantity} x ${item.price}
                    </div>
                  </div>

                  {/* Quantity Controls */}
                  <div className="flex items-center">
                    <button
                      className="px-3 py-1 bg-gray-200 rounded-md"
                      onClick={() => decreaseQuantity(item)}
                    >
                      -
                    </button>
                    <span className="mx-2">{item.quantity}</span>
                    <button
                      className="px-3 py-1 bg-gray-200 rounded-md"
                      onClick={() => increaseQuantity(item)}
                    >
                      +
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Checkout Button */}
        {cartItems.length > 0 && (
          <div className="p-4">
            <button
              className="w-full p-2 bg-black text-white rounded-lg transition hover:scale-105 duration-700 hover:drop-shadow-sm"
              onClick={handleCheckout}
            >
              Checkout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartButton;
