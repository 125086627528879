import React, { useState, useRef } from "react";

const MenuNav = ({ categories, selectedCategory, setSelectedCategory }) => {
  const [openCategory, setOpenCategory] = useState(null); // Track which parent category's dropdown is open
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track main dropdown visibility
  const dropdownRef = useRef(null); // Reference for main dropdown

  const handleParentCategoryClick = (categoryId, hasSubcategories) => {
    setSelectedCategory(categoryId);

    if (!hasSubcategories) {
      setIsDropdownOpen(false); // Close the main dropdown if no subcategories
    } else {
      setOpenCategory((prev) => (prev === categoryId ? null : categoryId)); // Toggle subcategory dropdown
    }
  };

  const handleSubcategoryClick = (subcategoryId) => {
    setSelectedCategory(subcategoryId);
    setOpenCategory(null); // Close subcategory dropdown
    setIsDropdownOpen(false); // Close the main dropdown
  };

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setOpenCategory(null); // Close any open subcategory dropdown
    }
    setIsDropdownOpen((prev) => !prev); // Toggle main menu
  };

  const closeMenu = () => {
    setIsDropdownOpen(false); // Close the main menu
    setOpenCategory(null); // Close any open subcategory dropdown
  };

  const renderCategories = (categories) => {
    return categories.map((category) => (
      <li key={category.id} className="relative">
        {/* Parent Category Button */}
        <button
          onClick={() =>
            handleParentCategoryClick(
              category.id,
              category.subcategories?.length > 0
            )
          }
          className={`block px-4 py-2 text-sm w-full text-left hover:bg-gray-200 rounded-md transition-all duration-200 ${
            selectedCategory === category.id ? "font-bold" : ""
          }`}
        >
          {category.name}
        </button>

        {/* Subcategories Dropdown */}
        {openCategory === category.id && category.subcategories?.length > 0 && (
          <ul
            className="absolute bg-white text-black mt-2 left-full top-0 rounded shadow-xl w-60 z-50 p-4 border border-gray-300"
            onClick={(e) => e.stopPropagation()}
          >
            {category.subcategories.map((subcategory) => (
              <li key={subcategory.id} className="mb-2 last:mb-0">
                <button
                  onClick={() => handleSubcategoryClick(subcategory.id)}
                  className={`block px-4 py-2 text-sm w-full text-left rounded hover:bg-gray-200 transition-all duration-200 ${
                    selectedCategory === subcategory.id
                      ? "font-bold bg-blue-50"
                      : ""
                  }`}
                >
                  {subcategory.name}
                </button>
              </li>
            ))}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <nav className="relative p-4 z-40 bg-gray-100 text-black">
      {/* Dropdown Button */}
      <div ref={dropdownRef} className="inline-block relative w-full">
        <button
          onClick={toggleDropdown}
          className="text-lg font-medium focus:outline-none hover:bg-gray-200 px-4 py-2 rounded-full transition-all duration-500 shadow border hover:shadow-lg animate-fadeIn ease-in-out"
        >
          Categories
        </button>

        {/* Background Tint */}
        {isDropdownOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30"
            onClick={closeMenu}
          ></div>
        )}

        {/* Parent Categories Dropdown Menu */}
        <ul
          className={`absolute bg-white text-black mt-2 rounded shadow-lg w-full z-40 grid grid-cols-5 gap-2 p-2 transition-all duration-300 origin-top ${
            isDropdownOpen ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
          }`}
          style={{ transformOrigin: "top" }}
        >
          <li className="hover:bg-gray-200">
            <button
              onClick={() => {
                setSelectedCategory(null);
                closeMenu();
              }}
              className={`block px-4 py-2 text-sm w-full text-left hover:bg-gray-200 ${
                selectedCategory === null ? "font-bold" : ""
              }`}
            >
              All
            </button>
          </li>
          {renderCategories(categories)}
        </ul>
        {/* Add column dividers */}
        <style>
          {`
          .grid-cols-5 > :not(:last-child) {
            border-right: 1px solid #e5e5e5; /* Light gray border for dividers */
          }
          `}
        </style>
      </div>
    </nav>
  );
};

export default MenuNav;
