import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuNav from "./MenuNav";
import ProductList from "./ProductList";
import CartButton from "./CartButton";
import useCart from "../utility/CartUtils"; // Import the custom hook

const ProductPage = () => {
  const location = useLocation();
  const isMenuPage = location.pathname === "/products";

  const {
    categories,
    cartItemCount,
    isLoadingCategories,
    updateCartItemCount,
  } = useCart(isMenuPage); // Use the custom hook to handle cart and category logic

  const [selectedCategory, setSelectedCategory] = useState(null); // Start with null for "All" by default
  const navigate = useNavigate(); // Use this to navigate to ProductDetails

  const handleProductClick = (productId) => {
    // Navigate to ProductDetails with the selected productId
    navigate(`/product/${productId}`);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Cart Button */}
      <CartButton
        cartItemCount={cartItemCount}
        updateCartItemCount={updateCartItemCount}
        className="fixed top-4 right-4 z-10" // Position the cart button at the top-right
      />

      {/* Navbar */}
      <div className="bg-zinc-600 text-white">
        {isLoadingCategories ? (
          <p className="p-4">Loading categories...</p>
        ) : (
          <MenuNav
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </div>

      {/* Product List */}
      <div className="flex-grow p-6 bg-gray-100">
        <ProductList
          selectedCategory={selectedCategory}
          categories={categories}
          updateCartItemCount={updateCartItemCount}
          onProductClick={handleProductClick}
        />
      </div>
    </div>
  );
};

export default ProductPage;
