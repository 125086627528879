import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/api";
import { useNavigate } from "react-router-dom";
import Banner from "../ui/Banner";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // For displaying login errors
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
    }
  }, [error]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(""); // Reset the error before a new login attempt

    try {
      const response = await axiosInstance.post(
        "/users/token/",
        {
          username,
          password,
        },
        {
          withCredentials: true, // Ensures cookies are set
        }
      );

      // If login is successful, navigate to the home page
      navigate("/");
    } catch (err) {
      // Ensure the error response is being logged correctly
      if (err.response) {
        // Check for the specific error response message
        if (
          err.response.data.detail ===
            "Your account has not been approved by an administrator." &&
          err.response.data.code === "authentication_failed"
        ) {
          setShowDialog(true); // Show the dialog box
        } else if (err.response.status === 401) {
          setError("Invalid username or password. Please try again.");
        } else {
          setError("Login failed. Please try again later.");
        }
      } else {
        // Log other types of errors (e.g., network errors)
        console.error("Error without response:", err);
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <div>
      <Banner />

      <div className="flex flex-col items-center justify-center min-h-[795px] bg-gray-100">
        {/* Login Form */}
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-md">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Login
          </h2>
          <form onSubmit={handleLogin} className="space-y-4">
            {/* Username */}
            <div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            {/* Password */}
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            {/* Error message */}
            {error && (
              <div className="bg-red-100 text-red-700 border border-red-400 p-3 rounded">
                {error}
              </div>
            )}

            {/* Submit button */}
            <button
              type="submit"
              className="w-full py-3 bg-black text-white rounded-md hover:scale-105 transition duration-300"
            >
              Login
            </button>
          </form>

          <p className="text-center text-sm text-gray-600 mt-4">
            Don't have an account?{" "}
            <a href="/register" className="text-blue-500 hover:underline">
              Create an account now!
            </a>
          </p>
        </div>
      </div>

      {/* Dialog Box */}
      {showDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h3 className="text-lg font-bold mb-4">Account Not Approved</h3>
            <p className="text-sm text-gray-700 mb-4">
              Your account has not been approved by an administrator. Please try
              again later or contact support for assistance.
            </p>
            <button
              onClick={() => {
                closeDialog();
                navigate("/");
              }}
              className="bg-black text-white px-4 py-2 rounded-md hover:scale-105 transition"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
