import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import ProfileImage from "./users/ProfileImage";
import axiosInstance, { checkAuth } from "../api/api";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Check if the user is logged in using the checkAuth function
  useEffect(() => {
    const handleAuth = async () => {
      const authStatus = await checkAuth();
      if (authStatus === "authenticated") {
        setIsLoggedIn(true);
      } else if (authStatus === "guest") {
        setIsLoggedIn(false);
      } else if (
        authStatus === "session-expired" ||
        authStatus === "no-refresh-token"
      ) {
        setIsLoggedIn(false);
        navigate("/login");
        // Optionally redirect to login page here
      } else {
      }
    };

    handleAuth();
  }, []);

  // Handle logout functionality
  const handleLogout = () => {
    axiosInstance.post("users/logout/");
    setIsLoggedIn(false);
    navigate("/login");
  };

  return (
    <div
      className={`fixed top-0 w-full z-50 transition-transform duration-300 ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      } ${!nav ? "bg-transparent" : "bg-white rounded-md"}`}
    >
      <div className="flex justify-between items-center h-24 w-full mx-auto px-4 text-white bg-zinc-600 rounded-sm">
        <h1 className="w-full text-3xl text-white font-serif italic">
          <a href="#home">MNK DISTRO</a>
        </h1>
        <ul className="hidden md:flex items-center font-semibold">
          <li className="p-4 transition duration-300 ease-in-out transform hover:scale-105">
            <a href="#home">Home</a>
          </li>
          <li className="p-4 transition duration-300 ease-in-out transform hover:scale-105">
            <a href="#explore">Explore</a>
          </li>
          <li className="p-4 transition duration-300 ease-in-out transform hover:scale-105">
            <a href="#contact">Contact</a>
          </li>
          {/* Conditionally render login button or profile icon */}
          {!isLoggedIn ? (
            <li className="p-4 transition duration-300 ease-in-out transform hover:scale-105">
              <Link
                to="/login"
                className="inline-block px-4 py-2 border border-transparent rounded hover:border-white hover:bg-white hover:text-black transition-all"
              >
                Login
              </Link>
            </li>
          ) : (
            <div className="flex items-center space-x-4 p-3">
              <ProfileImage handleLogout={handleLogout} />{" "}
              {/* Profile Image and dropdown */}
            </div>
          )}
        </ul>
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        {/* menu and menu opening transition*/}
        <ul
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-screen border-r border-r-gray-900 ease-in-out duration-500 z-20 bg-zinc-600"
              : "fixed left-[-100%]"
          }
        >
          <h1 className="w-full text-3xl font-bold text-white m-4">
            <a href="#home" onClick={handleNav}>
              MNKDISTRO
            </a>
          </h1>
          <li className="p-4 border-b border-white" onClick={handleNav}>
            <a href="#home">Home</a>
          </li>
          <li className="p-4 border-b border-white" onClick={handleNav}>
            <a href="#explore">Explore</a>
          </li>
          <li className="p-4 border-b border-white" onClick={handleNav}>
            <a href="#contact">Contact</a>
          </li>
          {/* Conditionally render login button or profile icon */}
          {!isLoggedIn ? (
            <li className="p-4">
              <Link to="/login">Login</Link>
            </li>
          ) : (
            <>
              <li className="p-4 border-b border-white">
                <Link to="/dashboard">Account</Link>
              </li>
              <li className="p-4  border-white" onClick={handleLogout}>
                Logout
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
