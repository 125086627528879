import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AgeVerification = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const isVerified = localStorage.getItem("ageVerified");
    if (isVerified === "true") {
      setIsVisible(false);
    }
  }, []);

  const handleVerification = (isVerified) => {
    if (isVerified) {
      localStorage.setItem("ageVerified", "true");
      setIsVisible(false);
    } else {
      navigate(-1);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Age Verification</h2>
        <p className="mb-6">
          You must be 21 years or older to shop on this site.
        </p>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={() => handleVerification(true)}
            className="bg-black text-white px-6 py-2 rounded-lg transition duration-200 ease-in-out hover:bg-gray-800"
          >
            Yes, I am 21 or older
          </button>
          <button
            onClick={() => handleVerification(false)}
            className="bg-white text-black border border-gray-400 px-6 py-2 rounded-lg transition duration-200 ease-in-out hover:bg-gray-200"
          >
            No, I am under 21
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;
