import React from "react";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../ui/dropdown";
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import { Link } from "react-router-dom";
import PFP from "../../assets/pfp.jpg";

const ProfileImage = ({ handleLogout }) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Avatar className="w-8 h-8 rounded-full cursor-pointer">
					<AvatarImage
						src={PFP}
						alt="profile-pic-default"
					/>
					<AvatarFallback>CN</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="absolute mt-2 w-48 bg-white rounded-md shadow-lg z-50"
				sideOffset={5}
				style={{ left: "50%", transform: "translateX(-50%)" }} // Align to the center of the trigger
			>
				<DropdownMenuLabel>My Account</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuItem asChild>
					<Link to="/dashboard">Profile</Link>
				</DropdownMenuItem>
				{/* <DropdownMenuItem>Billing</DropdownMenuItem> */}
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default ProfileImage;
