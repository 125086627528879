import React from "react";

const Banner = () => {
  return (
    <div className="w-full bg-zinc-600 text-white p-4 flex justify-between items-center shadow-targetStyle z-50 relative">
      <h1 className="text-3xl italic font-serif">
        <a href="/">MNK DISTRO</a>
      </h1>
    </div>
  );
};

export default Banner;
