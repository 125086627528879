import React, { useState, useEffect } from "react";
import {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  fetchProducts,
  groupByCategory,
} from "../utility/CartUtils";

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination";

const ProductList = ({
  selectedCategory,
  updateCartItemCount,
  onProductClick,
}) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 50;
  const maxVisiblePages = 5; // Maximum number of page links to display

  useEffect(() => {
    fetchProducts(setProducts, setQuantities, setIsLoading);
  }, []);

  const handleAddToCart = async (productId) => {
    await addToCart(productId, quantities[productId], updateCartItemCount);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: 1,
    }));
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  let displayProducts;

  if (selectedCategory) {
    displayProducts = products
      .filter((product) =>
        product.category.some((cat) => cat.id === selectedCategory)
      )
      .slice(indexOfFirstProduct, indexOfLastProduct);
  } else {
    const groupedProducts = groupByCategory(products);
    const flattenedProducts = Object.values(groupedProducts).flat();
    displayProducts = flattenedProducts.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );
  }

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(
    selectedCategory
      ? products.filter(
          (product) =>
            Array.isArray(product.category) &&
            product.category.some((cat) => cat.id === selectedCategory)
        ).length / productsPerPage
      : Object.values(groupByCategory(products)).flat().length / productsPerPage
  );

  // Calculate the range of visible pages
  const getVisiblePageNumbers = () => {
    const halfRange = Math.floor(maxVisiblePages / 2);
    let start = Math.max(currentPage - halfRange, 1);
    let end = Math.min(start + maxVisiblePages - 1, totalPages);

    if (end - start < maxVisiblePages - 1) {
      start = Math.max(end - maxVisiblePages + 1, 1);
    }

    const visiblePages = [];
    for (let i = start; i <= end; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  };

  const visiblePages = getVisiblePageNumbers();

  return (
    <div className="p-4">
      {isLoading ? (
        <p>Loading products...</p>
      ) : products.length === 0 ? (
        <p>No products available.</p>
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {displayProducts.map((product) => (
              <div
                key={product.id}
                className="relative flex flex-col items-center group"
              >
                {/* Product Image with Overlay */}
                <div
                  className="relative w-full max-w-[300px] h-[300px] overflow-hidden rounded-md group cursor-pointer"
                  onClick={() => {
                    if (product.is_available) onProductClick(product.id); // Only allow click if the product is available
                  }}
                >
                  {/* Product Image */}
                  {product.images && product.images.length > 0 ? (
                    <img
                      src={
                        product.images[0].image_file
                          ? `https://admin.mnkdistro.com${product.images[0].image_file}`
                          // `https://admin.mnkdistro.com${product.images[0].image_file}`
                          : product.images[0].image_url
                      }
                      alt={product.name}
                      className={`w-full h-full object-cover ${
                      product.quantity_on_hand > 0
                        ? "transition-opacity duration-300 group-hover:opacity-50 group-hover:bg-slate-400"
                        : "opacity-50 bg-black"
                    }`}
                    />
                  ) : (
                    <p className="text-gray-500">No image available</p>
                  )}

                  {/* Add to Cart Button or Out of Stock */}
                  <button
                    className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm px-6 py-2 rounded-full transition-all duration-300 ${
                      product.quantity_on_hand > 0
                        ? "bg-transparent text-black border border-black opacity-0 group-hover:opacity-100 hover:bg-black hover:text-white"
                        : "bg-gray-300 text-gray-700 border border-gray-300 cursor-not-allowed opacity-100"
                    }`}
                    onClick={(e) => {
                      if (product.is_available) {
                        e.stopPropagation(); // Prevent redirection when clicking the button
                        handleAddToCart(product.id);
                      }
                    }}
                    disabled={!product.is_available}
                  >
                    {product.quantity_on_hand > 0 ? "Add to Cart" : "Out of Stock"}
                  </button>
                </div>

                {/* Product Name */}
                <h3 className="text-center font-medium text-gray-900 text-sm mt-3 truncate">
                  {product.name}
                </h3>

                {/* Product Price */}
                <p className="text-center text-gray-600 text-md font-semibold">
                  ${product.price}
                </p>

                {/* Out of Stock Overlay */}
                {!product.is_available && (
                  <div className="absolute inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center text-white font-bold text-xl">
                    Out of Stock
                  </div>
                )}
              </div>
            ))}
          </div>

          {totalPages > 1 && (
            <Pagination className="mt-6" style={{ userSelect: "none" }}>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                </PaginationItem>

                {/* Show ellipsis if the first visible page is not the first page */}
                {visiblePages[0] > 1 && (
                  <>
                    <PaginationItem>
                      <PaginationLink onClick={() => handlePageChange(1)}>
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <span>...</span>
                    </PaginationItem>
                  </>
                )}

                {visiblePages.map((page) => (
                  <PaginationItem key={page}>
                    <PaginationLink
                      isActive={currentPage === page}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {/* Show ellipsis if the last visible page is not the last page */}
                {visiblePages[visiblePages.length - 1] < totalPages && (
                  <>
                    <PaginationItem>
                      <span>...</span>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => handlePageChange(totalPages)}
                      >
                        {totalPages}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                )}

                <PaginationItem>
                  <PaginationNext
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          )}
        </>
      )}
    </div>
  );
};

export default ProductList;
