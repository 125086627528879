import React from "react";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";

const Footer = () => {
	return (
		<div className="w-full bg-zinc-600 text-gray-300 py-4 px-4">
			<div className="max-w-[1240px] mx-auto flex justify-between items-center">
				{/* Social Media Icons */}
				<div className="flex space-x-4">
					<a
						href="https://www.facebook.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaFacebookSquare
							size={30}
							className="text-gray-300 hover:text-gray-400 hover:scale-105"
						/>
					</a>
					<a
						href="https://www.instagram.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaInstagramSquare
							size={30}
							className="text-gray-300 hover:text-gray-400 hover:scale-105"
						/>
					</a>
				</div>

				{/* Navigation Links */}
				<div className="flex space-x-6 text-sm">
					<a
						href="#home"
						className="hover:text-gray-400 hover:scale-105"
					>
						FEEDBACK
					</a>
					<a
						href="#explore"
						className="hover:text-gray-400 hover:scale-105"
					>
						EXPLORE
					</a>
					<a
						href="#contact"
						className="hover:text-gray-400 hover:scale-105"
					>
						CONTACT US
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
