import { useState, useEffect } from "react";
import axiosInstance, {
  clearTokensAndLogout,
  checkAuth,
  fetchProducts as fetchProductsFromAPI,
} from "../../api/api";

const useCart = (isMenuPage = false) => {
  const [categories, setCategories] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  // Function to update the cart item count
  const updateCartItemCount = async () => {
    try {
      const authStatus = await checkAuth(); // Check if the user is authenticated before proceeding

      if (authStatus === "authenticated") {
        const response = await axiosInstance.get("cart/");

        const totalItems = response.data.items.reduce(
          (sum, item) => sum + item.quantity,
          0
        );

        setCartItemCount(totalItems); // Update the cart item count
      } else {
        console.warn("User is not authenticated. Redirecting to login.");
        clearTokensAndLogout();
      }
    } catch (error) {
      console.error("Error while fetching cart item count:", error);

      if (error.response?.status === 401) {
        const authStatus = await checkAuth(); // Attempt to refresh the token

        if (authStatus === "authenticated") {
          await updateCartItemCount(); // Retry updating cart item count after refreshing the token
        } else {
          clearTokensAndLogout(); // Log out if refreshing token fails
        }
      } else {
        console.error(
          "Failed to fetch cart item count due to an unknown error:",
          error
        );
      }
    }
  };

  // Function to fetch categories
  const fetchCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const response = await axiosInstance.get("inventory/categories/");
      setCategories(response.data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  // Fetch categories and cart count on component mount
  useEffect(() => {
    if (isMenuPage) {
      fetchCategories();
      updateCartItemCount();
    }
  }, [isMenuPage]);

  return {
    categories,
    cartItemCount,
    isLoadingCategories,
    updateCartItemCount,
  };
};

export default useCart;

// Function to add a product to the cart
export const addToCart = async (productId, quantity, updateCartItemCount) => {
  const data = { product_id: productId, quantity };

  try {
    const authStatus = await checkAuth(); // Check if the user is authenticated before proceeding

    if (authStatus === "authenticated") {
      const response = await axiosInstance.post("cart/", data);
      await updateCartItemCount(); // After successfully adding to cart, update the cart item count
    } else {
      console.warn("User is not authenticated. Redirecting to login.");
      clearTokensAndLogout();
    }
  } catch (error) {
    if (error.response?.status === 401) {
      const authStatus = await checkAuth(); // Attempt to refresh the token

      if (authStatus === "authenticated") {
        try {
          const retryResponse = await axiosInstance.post("cart/", data); // Retry the request with the new token
          await updateCartItemCount(); // Update the cart item count after retry
        } catch (retryError) {
          console.error("Retry failed to add product to cart:", retryError);
          alert("Failed to add product to cart after refreshing token.");
        }
      } else {
        clearTokensAndLogout(); // Log out if refreshing token fails
      }
    } else {
      console.error("Error adding product to cart:", error);
      alert("Failed to add product to cart.");
    }
  }
};

// Function to fetch cart items for authenticated users
export const fetchCartItems = async (
  setCartItems,
  updateCartItemCount,
  setIsLoading
) => {
  try {
    setIsLoading(true);

    // Check if the user is authenticated before proceeding
    const authStatus = await checkAuth();

    if (authStatus === "authenticated") {
      // Fetch the cart items from the backend
      const response = await axiosInstance.get("cart/");
      const cartItems = response.data.items;

      // Update the state with the fetched cart items
      setCartItems(cartItems);

      // Calculate the total quantity of items in the cart
      const totalItems = cartItems.reduce(
        (sum, item) => sum + item.quantity,
        0
      );
      updateCartItemCount(totalItems); // Update cart item count in state
    } else {
      console.warn("User is not authenticated. Redirecting to login.");
      clearTokensAndLogout(); // Handle unauthenticated users
    }
  } catch (error) {
    console.error("Error fetching cart items:", error);
  } finally {
    // Ensure loading state is reset
    setIsLoading(false);
  }
};

// Function to remove an item from the cart
export const removeItemFromCart = async (itemId, fetchCartItems) => {
  try {
    const authStatus = await checkAuth(); // Check if the user is authenticated before proceeding

    if (authStatus === "authenticated") {
      await axiosInstance.delete(`cart/item/${itemId}/remove/`);
      fetchCartItems(); // Refetch cart items after removing
    } else {
      console.warn("User is not authenticated. Redirecting to login.");
      clearTokensAndLogout();
    }
  } catch (error) {
    console.error("Error removing item:", error);
    alert("Failed to remove item from cart.");
  }
};

// Utility function to fetch products using the shared API logic
export const fetchProducts = async (
  setProducts,
  setQuantities,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const products = await fetchProductsFromAPI(); // Use the API function to fetch products
    setProducts(products);

    // Initialize quantities for each product
    const initialQuantities = products.reduce((acc, product) => {
      acc[product.id] = 1; // Default quantity is 1
      return acc;
    }, {});
    setQuantities(initialQuantities);
  } catch (error) {
    console.error("Failed to fetch products", error);
  } finally {
    setIsLoading(false);
  }
};

// Function to group products by category
export const groupByCategory = (products) => {
  return products.reduce((acc, product) => {
    const categoryName = product.category[0].name; // Each product should have one category

    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(product); // Group products by category

    return acc;
  }, {});
};

// Utility function to increment quantity for a product
export const incrementQuantity = (productId, quantities, setQuantities) => {
  setQuantities((prevQuantities) => ({
    ...prevQuantities,
    [productId]: prevQuantities[productId] + 1,
  }));
};

// Utility function to decrement quantity for a product
export const decrementQuantity = (productId, quantities, setQuantities) => {
  setQuantities((prevQuantities) => ({
    ...prevQuantities,
    [productId]:
      prevQuantities[productId] > 1 ? prevQuantities[productId] - 1 : 1,
  }));
};
